import React from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import { sizing } from "../styles/sizing";
import { Grid } from "./icons/Grid";
import { colors } from "../styles/colors";
import { fontSizing } from "../styles/fontSizing";

const Container = styled.div({});

const Overlay = styled.div({
  position: "fixed",
  inset: 0,
  backgroundColor: "rgba(0,0,0,.5)",
  zIndex: "1000",
});

const TransactionBox = styled.div({
  position: "absolute",
  top: "25%",
  bottom: "25%",
  left: 0,
  right: 0,
  maxWidth: 600,
  margin: "0 auto",
  backgroundColor: colors.blue1,
  zIndex: "1001",
  overflow: "hidden",
  boxShadow: "1rem 1rem rgba(0, 0, 0, .5)",
  color: colors.white3,
  fontFamily: "'Crimson Pro', serif",
  ...fontSizing.xxl,
});

const ModalHeader = styled.div({
  padding: sizing[5],
  borderBottom: `1px solid ${colors.blue2}`,
});

const ModalContent = styled.div({
  padding: sizing[5],
  position: "relative",
  overflowY: "auto",
  height: "100%",
});

const CloseButton = styled.button({});

const GridContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  p: {
    marginTop: sizing[5],
  },
});

export const TransactionModal = ({ open, onClose, transactionHash, error }) => {
  return ReactDOM.createPortal(
    <Container>
      <Overlay>
        <TransactionBox>
          <ModalHeader>
            <CloseButton onClick={onClose}>Close</CloseButton>
          </ModalHeader>
          <ModalContent>
            <div>
              {transactionHash && (
                <>
                  <GridContainer>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="150"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <p>Transaction succesful!</p>
                    <p>
                      <a
                        href={`https://etherscan.io/tx/${transactionHash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View on etherscan
                      </a>
                    </p>
                  </GridContainer>
                </>
              )}
              {error === null && transactionHash === null && (
                <>
                  <GridContainer>
                    <Grid fill={colors.yellow2} />
                    <p>Transaction pending.</p>
                  </GridContainer>
                </>
              )}
              {error === 4001 && <>Transaction rejected.</>}
              {error === -3602 && (
                <>Invalid parameters supplied to function call.</>
              )}
              {error === -3603 && <>An unexpected error has occured.</>}
            </div>
          </ModalContent>
        </TransactionBox>
      </Overlay>
    </Container>,
    document.getElementById("portal")
  );
};
