import { ethers } from "ethers";
import { getEthereumProvider, getContractAddress } from "../env";
import { BN } from "bn.js";

const ethereumProvider = getEthereumProvider();
const contractAddress = getContractAddress();

export const whitelistMaxMint = async (address) => {
  const ABI = ["function whitelistMaxMint(address address) external view returns (uint)"];
  const provider = ethers.getDefaultProvider(ethereumProvider);
  const contract = new ethers.Contract(contractAddress, ABI, provider);
  const maxMint = await contract.whitelistMaxMint(address);

  return parseInt(maxMint._hex);
};
