import styled from "@emotion/styled";
import { defaultWidth } from "../styles/variables";
import { fontSizing } from "../styles/fontSizing";
import { sizing } from "../styles/sizing";
import background from "../assets/desolate-jungle.gif";
import { colors } from "../styles/colors";

const Container = styled.div({
  backgroundAttachment: "fixed",
  backgroundImage: `url(${background})`,
  imageRendering: "pixelated",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: "calc(100vh - 88px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const Content = styled.div({
  "@media(min-width: 1080px)": {
    maxWidth: defaultWidth,
    margin: `0 auto`,
  },
  padding: `0 ${sizing[10]}`,
});

const Title = styled.p({
  "@media(min-width: 930px)": {
    ...fontSizing.t7xl,
  },
  ...fontSizing.t6xl,
  marginBottom: sizing[5],
  color: colors.yellow2,
  fontFamily: "terminal",
  textAlign: "center",
  letterSpacing: ".25rem",
});

const Description = styled.p({
  "@media(min-width: 930px)": {
    ...fontSizing.t4xl,
  },
  ...fontSizing.xxl,
  textAlign: "center",
  color: colors.yellow4,
  fontFamily: "terminal",
  letterSpacing: ".1rem",
});

const About = () => {
  return (
    <Container id="about">
      <Content>
        <Title>Welcome to the Paradox</Title>
        <Description>
          Panther Paradox is a collection of procedurally generated pixelated
          panthers NFTs roaming deep inside the Ethereum blockchain. The genesis
          panthers consist of 4444 felines, each randomly composed from a large
          variety of traits with endless combination possibilities, making each
          panther unique.
        </Description>
      </Content>
    </Container>
  );
};

export { About };
