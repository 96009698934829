import styled from "@emotion/styled";
import { fontSizing } from "../styles/fontSizing";
import { sizing } from "../styles/sizing";
// import { colors } from "../styles/colors";
import { defaultWidth } from "../styles/variables";
import background from "../assets/waterfall.gif";
import { colors } from "../styles/colors";

const Background = styled.div({
  background: "rgba(8,33,16,1)",
  backgroundAttachment: "fixed",
  backgroundImage: `radial-gradient(circle, rgba(255,255,255,.5) 0%, rgba(255,255,255,0) 80%), url(${background})`,
  imageRendering: "pixelated",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
});

const Container = styled.div({
  "@media(min-width: 930px)": {
    display: "flex",
    flexFlow: "column",
    padding: `${sizing[32]} 0`,
  },
  "@media(min-width: 1080px)": {
    maxWidth: defaultWidth,
    margin: `0 auto`,
  },
  padding: `0 ${sizing[10]}`,
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  height: "100vh",
  alignItems: "center",
  justifyContent: "center",
});

const Title = styled.p({
  "@media(min-width: 930px)": {
    ...fontSizing.t8xl,
  },
  ...fontSizing.t7xl,
  marginBottom: sizing[5],
  color: colors.blue2,
  fontFamily: "terminal",
  textAlign: "center",
  letterSpacing: ".25rem",
});

const Description = styled.p({
  "@media(min-width: 930px)": {
    ...fontSizing.t4xl,
  },
  ...fontSizing.xxl,
  textAlign: "center",
  color: colors.blue,
  fontFamily: "terminal",
  letterSpacing: ".1rem",
});

const Home = () => {
  return (
    <Background>
      <Container id="home">
        <Title>Panther Paradox</Title>
        <Description>
          Welcome to the Paradox. 4444 genesis panthers generating $CLAW.
          Utility. Web3 Expansion. CCo.
        </Description>
      </Container>
    </Background>
  );
};

export { Home };
