import styled from "@emotion/styled";
import { sizing } from "../styles/sizing";
import { defaultWidth } from "../styles/variables";
import { fontSizing } from "../styles/fontSizing";
import { colors } from "../styles/colors";

const Background = styled.div({
  background: colors.green2,
  padding: `${sizing[20]} ${sizing[10]}`,
  position: "relative",
  border: "solid black",
  borderWidth: "5px 0",
});

const Container = styled.div({
  "@media(min-width: 1080px)": {
    maxWidth: defaultWidth,
    margin: `0 auto`,
  },
  fontFamily: "terminal",
  fontWeight: "600",
});

const ClawMainInfo = styled.div({
  textAlign: "center",
});

const ClawItems = styled.div({
  "@media(min-width:480px)": {
    display: "block",
  },
  "@media(min-width:768px)": {
    display: "flex",
  },
});

const ClawItem = styled.div({
  flexBasis: "100%",
});

const Card = styled.div({
  height: "100%",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  ...fontSizing.xxl,
  color: colors.yellow4,
});

// const Title = styled.p({
//   "@media(min-width:768px)": {
//     ...fontSizing.t7xl,
//     padding: `0 ${sizing[24]}`,
//   },
//   ...fontSizing.t6xl,
//   color: colors.yellow2,
//   marginBottom: sizing[5],
//   letterSpacing: ".25rem",
//   fontFamily: "virus killer",
//   fontWeight: "normal",
// });

// const Subtitle = styled.p({
//   "@media(min-width:768px)": {
//     ...fontSizing.t4xl,
//     padding: `${sizing[20]} ${sizing[24]}`,
//   },
//   ...fontSizing.xxl,
//   marginBottom: sizing[10],
//   color: colors.yellow4,
// });

const Title = styled.p({
  ...fontSizing.t7xl,
  color: colors.yellow2,
  marginBottom: sizing[5],
  letterSpacing: ".25rem",
  textAlign: "center",
});

const Subtitle = styled.p({
  "@media(min-width: 930px)": {
    ...fontSizing.t4xl,
  },
  ...fontSizing.xxxl,
  textAlign: "center",
  color: colors.yellow4,
  letterSpacing: ".1rem",
  marginBottom: sizing[5],
});

const Claw = () => {
  return (
    <Background>
      <Container id="claw">
        <ClawMainInfo>
          <Title>$Claw Token</Title>
          <Subtitle>
            Each Panther Paradox NFT will generate 10 $CLAW everyday, which
            holders will be able to redeem through this site in the near future.
          </Subtitle>
        </ClawMainInfo>
        <ClawItems>
          <ClawItem>
            <Card>
              $CLAW will be the entry token to the Panther Paradox universe.
              Everything will be powered by $CLAW.
            </Card>
          </ClawItem>
          <ClawItem>
            <Card>
              $CLAW liquidity pool will be funded by a percentage of secondary
              sales on Opensea.
            </Card>
          </ClawItem>
          <ClawItem>
            <Card>
              $CLAW is not an investment and has no real monetary value. It is
              purely and extension of the Panther Paradox Universe. 1$CLAW = 1
              $CLAW.
            </Card>
          </ClawItem>
        </ClawItems>
      </Container>
    </Background>
  );
};

export { Claw };
