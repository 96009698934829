import { ethers } from "ethers";
import { getEthereumProvider, getContractAddress, getMintAmount } from "../env";

const ethereumProvider = getEthereumProvider();
const contractAddress = getContractAddress();
const mintAmount = getMintAmount();

export const sendTransaction = async (address, numberOfTokens = 1) => {
  const ABI = ["function mint(uint numberOfTokens) payable"];
  const mintInterface = new ethers.utils.Interface(ABI);
  const data = mintInterface.encodeFunctionData("mint", [numberOfTokens]);
  const provider = ethers.getDefaultProvider(ethereumProvider);
  const gasPrice = await provider.getGasPrice()._hex;
  const value = (parseFloat(mintAmount) * numberOfTokens).toString();

  const parameters = {
    gasPrice,
    from: address,
    to: contractAddress,
    value: ethers.utils.parseEther(value)._hex,
    data
  };

  try {
    const hash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [parameters],
    });
    return [hash, null];
  } catch (error) {
    return [null, error.code];
  }
};
