import React, { useReducer, useEffect, useCallback, useMemo } from "react";
import { MetaMaskContext } from "./context";
import { reducer } from "./reducer";
import { useSafeDispatch } from "../hooks/useSafeDispatch";

const synchronize = async (dispatch) => {
  const ethereum = window.ethereum;
  const isMetaMaskAvailable = Boolean(ethereum) && ethereum.isMetaMask;

  if (!isMetaMaskAvailable) {
    dispatch({ type: "metaMaskUnavailable" });
    return;
  }

  const chainId = await ethereum.request({
    method: "eth_chainId",
  });

  const isUnlocked = await ethereum._metamask.isUnlocked();

  if (!isUnlocked) {
    dispatch({ type: "metaMaskLocked", payload: { chainId } });
    return;
  }

  const accessibleAccounts = await ethereum.request({
    method: "eth_accounts",
  });

  if (accessibleAccounts.length === 0) {
    dispatch({ type: "metaMaskUnlocked", payload: { chainId } });
  } else {
    dispatch({
      type: "metaMaskConnected",
      payload: { accounts: accessibleAccounts, chainId },
    });
  }
};

const subscribeToAccountsChanged = (dispatch) => {
  const ethereum = window.ethereum;
  const onAccountsChanged = (accounts) =>
    dispatch({ type: "metaMaskAccountsChanged", payload: accounts });

  ethereum.on("accountsChanged", onAccountsChanged);

  return () => {
    ethereum.removeListener("accountsChanged", onAccountsChanged);
  };
};

const subscribeToChainChanged = (dispatch) => {
  const ethereum = window.ethereum;
  const onChainChanged = (chainId) =>
    dispatch({ type: "metaMaskChainChanged", payload: chainId });

  ethereum.on("chainChanged", onChainChanged);

  return () => {
    ethereum.removeListener("chainChanged", onChainChanged);
  };
};

const requestAccounts = async (dispatch) => {
  const ethereum = window.ethereum;

  dispatch({ type: "metaMaskConnecting" });

  try {
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });

    dispatch({ type: "metaMaskConnected", payload: { accounts } });

    return accounts;
  } catch (err) {
    dispatch({ type: "metaMaskPermissionRejected" });

    throw err;
  }
};

const initialState = {
  status: "initializing",
  account: null,
  chainId: null,
};

export const MetaMaskProvider = (props) => {
  const [state, unsafeDispatch] = useReducer(reducer, initialState);
  const dispatch = useSafeDispatch(unsafeDispatch);
  const { status } = state;
  const isInitializing = status === "initializing";

  useEffect(() => {
    if (isInitializing) {
      synchronize(dispatch);
    }
  }, [dispatch, isInitializing]);

  const isConnected = status === "connected";
  useEffect(() => {
    if (!isConnected) return () => {};
    const unsubscribe = subscribeToAccountsChanged(dispatch);

    return unsubscribe;
  }, [dispatch, isConnected]);

  const isAvailable = status !== "unavailable" && status !== "initializing";
  useEffect(() => {
    if (!isAvailable) return () => {};
    const unsubscribe = subscribeToChainChanged(dispatch);

    return unsubscribe;
  }, [dispatch, isAvailable]);

  const connect = useCallback(() => {
    if (!isAvailable) {
      console.warn(
        "`enable` method has been called while MetaMask is not available or synchronising. Nothing will be done in this case."
      );

      return Promise.resolve([]);
    }

    return requestAccounts(dispatch);
  }, [dispatch, isAvailable]);

  const value = useMemo(
    () => ({
      ...state,
      connect,
      ethereum: isAvailable ? window.ethereum : null,
    }),
    [connect, state, isAvailable]
  );

  // TODO: Refactor into multiple contexts
  return <MetaMaskContext.Provider value={value} {...props} />;
};
