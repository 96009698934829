import styled from "@emotion/styled";
import image from "../assets/BLACK_PANTHER.png";
import { fontSizing } from "../styles/fontSizing";
import { colors } from "../styles/colors";
import { sizing } from "../styles/sizing";
import { defaultWidth } from "../styles/variables";
import { useMetaMask } from "../lib/metamask/useMetaMask";
import { sendTransaction } from "../lib/ethereum/sendTransaction";
import { useEffect, useState } from "react";
import { TransactionModal } from "./TransactionModal";
import { whitelistMaxMint } from "../lib/ethereum/whitelistMaxMint";
import { envFetch } from "../lib/env";
import { mintWhitelist } from "../lib/ethereum/mintWhitelist";

const Background = styled.div({
  background: colors.blue1,
  padding: `${sizing[20]} ${sizing[10]}`,
  position: "relative",
  border: "solid black",
  borderWidth: "5px 0",
});

const Container = styled.div({
  "@media(min-width: 1080px)": {
    maxWidth: defaultWidth,
    margin: `0 auto`,
  },
  "@media(min-width: 800px)": {
    flexDirection: "row",
  },
  display: "flex",
  flexDirection: "column-reverse",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "'Crimson Pro', serif",
});

const MintDescription = styled.div({
  "@media(min-width: 800px)": {
    display: "flex",
  },
  "@media(min-width: 480px)": {
    display: "block",
  },
  flexBasis: "60%",
});

const ImageContainer = styled.div({
  "@media(min-width: 800px)": {
    padding: `0 0 0 ${sizing[10]}`,
  },
  flexBasis: "40%",
  padding: `0 ${sizing[20]} ${sizing[10]} ${sizing[20]}`,
});

const Image = styled.img({
  display: "block",
  boxShadow: "1rem 1rem rgba(0, 0, 0, .4)",
});

const AmountContainer = styled.div({
  display: "flex",
  marginBottom: "1rem",
});

const PlusButton = styled.button({
  width: sizing[12],
  ...fontSizing.xl,
  color: colors.green2,
  background: colors.white2,
  borderRadius: "0 3px 3px 0",
  padding: `${sizing[2]} ${sizing[4]}`,
});

const Amount = styled.input({
  ...fontSizing.xl,
  textAlign: "center",
  width: "12rem",
  padding: `${sizing[2]} ${sizing[4]}`,
});

const MinusButton = styled.button({
  width: "3rem",
  ...fontSizing.xl,
  color: colors.green2,
  background: colors.white2,
  borderRadius: "3px 0 0 3px",
  padding: `${sizing[2]} ${sizing[4]}`,
});

const MintButton = styled.button({
  width: "18rem",
  ...fontSizing.xl,
  color: colors.green2,
  background: colors.white2,
  borderRadius: "3px",
  padding: `${sizing[2]} ${sizing[4]}`,
  "&:hover": {
    background: colors.white3,
  },
});

const Title = styled.p({
  ...fontSizing.t7xl,
  color: colors.yellow2,
  marginBottom: sizing[5],
  letterSpacing: ".25rem",
  fontFamily: "terminal",
});

const MintParagraph = styled.p({
  ":last-child": {
    marginBottom: 0,
  },
  ...fontSizing.xxl,
  marginBottom: sizing[5],
  color: colors.white3,
});

const Anchor = styled.div({
  width: 0,
  height: 76,
  position: "absolute",
  top: -76,
});

const whitelistMintEnabled =
  envFetch("REACT_APP_WHITELIST_MINT_ENABLED") === "true";
const publicMintEnabled = envFetch("REACT_APP_PUBLIC_MINT_ENABLED") === "true";

export const Mint = () => {
  const { ethereum } = useMetaMask();
  const [amount, setAmount] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [maxMint, setMaxMint] = useState();
  const [transactionHash, setTransactionHash] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ethereum && ethereum.selectedAddress) {
      const getMaxMint = async () => {
        const max = await whitelistMaxMint(ethereum.selectedAddress);
        setMaxMint(max);
      };
      getMaxMint();
    }
  }, [ethereum, setMaxMint]);

  const addAmount = () => {
    if (amount < 5) setAmount((amount) => amount + 1);
  };

  const minusAmount = () => {
    if (amount > 1) setAmount((amount) => amount - 1);
  };

  const onChange = (event) => {
    event.preventDefault();
    if (event.target.value < 1) {
      setAmount(1);
    }
    if (event.target.value > 5) {
      setAmount(5);
    }
  };

  const mint = async () => {
    setIsOpen(true);
    const [hash, error] = await mintWhitelist(ethereum.selectedAddress, amount);
    // const [hash, error] = await sendTransaction(
    //   ethereum.selectedAddress,
    //   amount
    // );
    if (hash) setTransactionHash(hash);
    if (error) setError(error);
  };

  const closeModal = () => {
    setIsOpen(false);
    setTransactionHash(null);
    setError(null);
  };

  return (
    <Background>
      <Anchor id="mint" />
      <Container>
        <MintDescription>
          <Title>Adopt your Panther</Title>
          <MintParagraph>
            4444 pixelated Panther Paradox NFTs generating $CLAW through the
            passage of time. Adopt your panther and enter the Paradox.
          </MintParagraph>
          <MintParagraph>
            Public mint available soon.
            <br />
            Whitelist mint available sooner.
          </MintParagraph>

          <MintParagraph>
            Mint your very own Unique panther for 0.06 ETH + gas. Whitelist mint
            is capped at 2 panthers per wallet. Public mint is limited to 5
            panthers per txn.
          </MintParagraph>
          {whitelistMintEnabled && !publicMintEnabled && maxMint > 0 && (
            <MintParagraph>
              Your address has been whitelisted! You can mint {maxMint}
              {maxMint > 1 ? " panthers" : " panther"}.
            </MintParagraph>
          )}
          {((whitelistMintEnabled && maxMint > 0) || publicMintEnabled) && (
            <>
              <AmountContainer>
                <MinusButton onClick={minusAmount}>-</MinusButton>
                <Amount value={amount} onChange={onChange} />
                <PlusButton onClick={addAmount}>+</PlusButton>
              </AmountContainer>
              <MintButton onClick={mint}>MINT</MintButton>
            </>
          )}
          {isOpen && (
            <TransactionModal
              onClose={closeModal}
              transactionHash={transactionHash}
              error={error}
            />
          )}
        </MintDescription>
        <ImageContainer>
          <Image src={image} alt="example nft" />
        </ImageContainer>
      </Container>
    </Background>
  );
};
