import styled from "@emotion/styled";
import background from "../assets/Monument.gif";
import { sizing } from "../styles/sizing";
import { fontSizing } from "../styles/fontSizing";
import { colors } from "../styles/colors";
import { TwitterIcon } from "./icons/TwitterIcon";
import { DiscordIcon } from "./icons/DiscordIcon";
import { OpenSeaIcon } from "./icons/OpenSeaIcon";

const Background = styled.div({
  backgroundAttachment: "fixed",
  backgroundImage: `url(${background})`,
  imageRendering: "pixelated",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
});

const Container = styled.div({
  display: "flex",
  flexFlow: "column wrap",
  justifyContent: "flex-end",
  height: "100vh",
});

const Welcome = styled.p({
  "@media(min-width:768px)": {
    ...fontSizing.t8xl,
  },
  padding: `${sizing[5]}`,
  ...fontSizing.t7xl,
  fontFamily: "terminal",
  color: colors.yellow1,
  letterSpacing: ".25rem",
  width: "100%",
});

const FooterBar = styled.footer({
  ...fontSizing.xxl,
  background: colors.blue,
  justifyContent: "space-between",
  color: "white",
  display: "inline-flex",
  alignItems: "center",
  fontFamily: "'Crimson Pro', serif",
  padding: sizing[5],
  minHeight: "76px",
  borderTop: "5px solid black",
  width: "100%",
});

const RightLinkIcons = styled.div({
  display: "flex",
  margin: 0,
  alignItems: "center",
  justifyContent: "space-between",
  a: {
    marginRight: sizing[5],
  },
});

const Link = styled.a({
  "&:hover": {
    color: colors.blue5,
  },
  "&:active": {
    color: colors.blue4,
  },
  marginBottom: sizing[1],
  "&:last-of-type": {
    marginRight: 0,
  },
});

const Footer = () => {
  return (
    <Background>
      <Container>
        <Welcome>Welcome to the Paradox.</Welcome>
        <FooterBar>
          <p className="rights">&copy; 2021 Panther Paradox</p>
          <RightLinkIcons>
            <div style={{ display: "flex" }}>
              <Link href="#">
                <DiscordIcon />
              </Link>
              <Link href="https://twitter.com/PantherParadoxx">
                <TwitterIcon />
              </Link>
              <Link href="#">
                <OpenSeaIcon />
              </Link>
            </div>
          </RightLinkIcons>
        </FooterBar>
      </Container>
    </Background>
  );
};

export { Footer };
