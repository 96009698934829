import { useContext } from "react"
import { MetaMaskContext } from "./context"

export function useMetaMask() {
  const context = useContext(MetaMaskContext)

  if (!context) {
    throw new Error("`useMetamask` should be used within a `MetaMaskProvider`")
  }

  return context
}
