export const getContractAddress = () => {
  const value = process.env.REACT_APP_CONTRACT_ADDRESS
  if (value === undefined) {
    throw new Error("Undefined environment variable, (Name: REACT_APP_CONTRACT_ADDRESS)")
  }

  return value
}

export const getEthereumProvider = () => {
  const value = process.env.REACT_APP_ETHEREUM_PROVIDER;
  if (value === undefined) {
    throw new Error("Undefined environment variable, (Name: REACT_APP_ETHEREUM_PROVIDER)")
  }

  return value
};

export const getMintAmount = () => {
  const value = process.env.REACT_APP_MINT_AMOUNT
  if (value === undefined) {
    throw new Error("Undefined environment variable, (Name: REACT_APP_MINT_AMOUNT)")
  }

  return value
}

export const envFetch = (key) => {
  const value = process.env[key]
  if (value === undefined) {
    throw new Error(`Undefined environment variable, (Name: ${key})`)
  }

  return value
}
