import image1 from "../assets/panthertest0.17.png";
import image2 from "../assets/pythontest36.png";
import image3 from "../assets/panthertest29.png";
import image4 from "../assets/panthertest35.png";
import image5 from "../assets/ROBO_PANTHER.png";
import styled from "@emotion/styled";
import { fontSizing } from "../styles/fontSizing";
import { sizing } from "../styles/sizing";
import { defaultWidth } from "../styles/variables";
import { colors } from "../styles/colors";

const Title = styled.p({
  ...fontSizing.t8xl,
  color: colors.yellow2,
  marginBottom: sizing[5],
  letterSpacing: ".25rem",
  fontFamily: "terminal",
  textAlign: "center",
});

const Background = styled.div({
  background: colors.blue1,
  padding: `${sizing[20]} ${sizing[10]}`,
  position: "relative",
  borderBottom: "5px solid black",
});

const Container = styled.div({
  "@media(min-width: 1080px)": {
    maxWidth: defaultWidth,
    margin: `0 auto`,
  },
  fontFamily: "'Crimson Pro',serif",
});

const Phase = styled.div({
  "@media(min-width: 600px)": {
    width: "50%",
    paddingRight: sizing[10],
    "&:nth-of-type(even)": {
      marginLeft: "auto",
      marginTop: "-200px",
      paddingLeft: sizing[10],
      paddingRight: 0,
    },
    "&:nth-of-type(odd)": {
      marginTop: "-200px",
    },
    "&:first-of-type": {
      marginTop: 0,
    },
  },
});

const PhaseTitle = styled.div({
  ...fontSizing.xxxl,
  fontWeight: "800",
  marginBottom: sizing[2],
  color: colors.blue5,
  fontFamily: "terminal",
  letterSpacing: ".25rem",
});

const PhaseSubtitle = styled.div({
  "@media(min-width:768px)": {
    ...fontSizing.t4xl,
  },
  ...fontSizing.xxl,
  fontWeight: "600",
  marginBottom: sizing[2],
  color: colors.white3,
  letterSpacing: ".1rem",
});

const PhaseText = styled.div({
  ...fontSizing.xxl,
  marginBottom: sizing[4],
  color: colors.white3,
});

const Divider = styled.div({
  background:
    "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
  height: 2,
  margin: `${sizing[4]} 0`,
});

const ImageContainer = styled.div({
  "@media(min-width: 600px)": {
    margin: `${sizing[5]} 0`,
  },
  margin: `${sizing[10]} ${sizing[20]} ${sizing[10]} ${sizing[20]}`,
});

const Image = styled.img({
  ...fontSizing.t6xl,
  // marginBottom: sizing[4],
  boxShadow: "1rem 1rem rgba(0, 0, 0, .4)",
});

const Roadmap = () => {
  return (
    <Background id="roadmap">
      <Container>
        <Title>Roadmap</Title>
        <Phase>
          <PhaseTitle>Phase 1</PhaseTitle>
          <PhaseSubtitle>Genesis Panther Mint</PhaseSubtitle>
          <Divider />
          <PhaseText>
            The genesis panthers mint will kick start the Panther Paradox
            universe. 4444 unique panthers will be released to roam wild and
            wreak havoc on the Ethereum blockchain.
          </PhaseText>
          <ImageContainer>
            <Image src={image1} />
          </ImageContainer>
        </Phase>
        <Phase>
          <PhaseTitle>Phase 2</PhaseTitle>
          <PhaseSubtitle>$CLAW Token Release</PhaseSubtitle>
          <Divider />
          <PhaseText>
            $CLAW Token will be released and available for all Panther Paradox
            NFT holders to claim daily.
          </PhaseText>
          <ImageContainer>
            <Image src={image3} />
          </ImageContainer>
        </Phase>
        <Phase>
          <PhaseTitle>Phase 3</PhaseTitle>
          <PhaseSubtitle>?????? ????????</PhaseSubtitle>
          <Divider />
          <PhaseText>
            Some panthers may have ventured a little too deep inside the
            rainforest. Phase 3 introduces the first of many added utilites to
            the $CLAW token. More information to be released soon, but we've
            heard you like mutants.
          </PhaseText>
          <ImageContainer>
            <Image src={image4} />
          </ImageContainer>
        </Phase>
        <Phase>
          <PhaseTitle>Phase 4</PhaseTitle>
          <PhaseSubtitle>
            Expansion of the Panther Paradox Universe
          </PhaseSubtitle>
          <Divider />
          <PhaseText>
            The universe will continue to grow as new members are introduced
            into the community and as they are continuously rewarded. Holders of
            Panther Paradox NFTs will have a say on the future development of
            the project and how the universe expands beyond its initial goals.
            Including but not limited to: additional utility for the $CLAW
            token, frequent airdrops, acquisition of metaverse real estate, and
            the construction of virtual museums.
          </PhaseText>
          <ImageContainer>
            <Image src={image2} />
          </ImageContainer>
        </Phase>
        <Phase>
          <PhaseTitle>Phase 5</PhaseTitle>
          <PhaseSubtitle>P2E Gamification</PhaseSubtitle>
          <Divider />
          <PhaseText>
            The Paradox will transform into a P2E ecosystem powered by $CLAW and
            the genesis panthers.
          </PhaseText>
          <ImageContainer>
            <Image src={image5} />
          </ImageContainer>
        </Phase>
      </Container>
    </Background>
  );
};

export { Roadmap };
