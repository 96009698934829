import styled from "@emotion/styled";
import { Navbar } from "./components/Navbar";
import { Home } from "./components/Home";
import { Footer } from "./components/Footer";
import { About } from "./components/About";
import { Claw } from "./components/Claw";
import { Mint } from "./components/Mint";
import { Roadmap } from "./components/Roadmap";
import { MetaMaskProvider } from "./lib/metamask/provider";
import { colors } from "./styles/colors";

const Container = styled.div({
  background: colors.green1,
});

const App = () => {
  return (
    <Container>
      <MetaMaskProvider>
        <Navbar />
        <Home />
        <Mint />
        <About />
        <Claw />
        <Roadmap />
        <Footer />
      </MetaMaskProvider>
    </Container>
  );
};

export { App };
