import { useState } from "react";
import styled from "@emotion/styled";
import { useMetaMask } from "../lib/metamask/useMetaMask";
import { sizing } from "../styles/sizing";
import { fontSizing } from "../styles/fontSizing";
import { colors } from "../styles/colors";
import { DiscordIcon } from "./icons/DiscordIcon";
import { OpenSeaIcon } from "./icons/OpenSeaIcon";
import { TwitterIcon } from "./icons/TwitterIcon";

const Container = styled.div({
  "@media(min-width: 930px)": {
    display: "flex",
    justifyContent: "space-between",
  },
  background: colors.blue,
  position: "fixed",
  width: "100%",
  zIndex: 1000,
  color: colors.white2,
  padding: sizing[5],
});

const Section = styled.div({
  "@media(min-width: 930px)": {
    display: "flex",
    alignItems: "center",
  },
});

const Logo = styled.a({
  ...fontSizing.t4xl,
  fontFamily: "terminal",
  marginRight: sizing[5],
  color: colors.yellow1,
});

const MenuLinks = styled.div(({ isOpen }) => ({
  "@media(min-width: 930px)": {
    display: "flex",
    margin: 0,
  },
  margin: `${sizing[5]} 0`,
  display: isOpen ? "block" : "none",
}));

const SocialLinks = styled.div(({ isOpen }) => ({
  "@media(min-width: 930px)": {
    display: "flex",
    margin: 0,
  },
  alignItems: "center",
  justifyContent: "space-between",
  display: isOpen ? "flex" : "none",
  a: {
    marginRight: sizing[5],
  },
}));

const LogoMenu = styled.div({
  "@media(min-width: 930px)": {
    button: {
      display: "none",
    },
  },
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Link = styled.a({
  "@media(min-width: 930px)": {
    marginBottom: 0,
    marginRight: sizing[3],
  },
  ...fontSizing.xxl,
  marginBottom: sizing[1],
  textDecoration: "none",
  display: "block",
  "&:last-of-type": {
    marginBottom: 0,
  },
  "&:hover": {
    color: colors.blue5,
  },
  "&:active": {
    color: colors.blue4,
  },
  fontFamily: "terminal",
});

const Button = styled.button({
  ...fontSizing.xxl,
  color: colors.green1,
  background: colors.white2,
  borderRadius: "3px",
  padding: `${sizing[2]} ${sizing[4]}`,
  fontFamily: "'Crimson Pro', serif",
  fontWeight: "500",
  "&:hover": {
    background: colors.white3,
  },
});

const ConnectToMetaMask = () => {
  const { status, connect } = useMetaMask();

  if (status === "initializing") {
    return <Button disabled>Connect</Button>;
  }

  if (status === "unavailable") {
    return <Button disabled>Unavailable</Button>;
  }

  if (status === "notConnected") {
    return <Button onClick={connect}>Connect</Button>;
  }

  if (status === "connecting") {
    return <Button disabled>Connecting...</Button>;
  }

  if (status === "connected") {
    return <Button disabled>Connected</Button>;
  }
};

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const clickHamburger = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <Container>
      <Section>
        <LogoMenu>
          <Logo href="#">Panther Paradox</Logo>
          <Button onClick={clickHamburger}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="32"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </Button>
        </LogoMenu>
        <MenuLinks isOpen={isOpen}>
          <Link href="#home">Home</Link>
          <Link href="#mint">Mint</Link>
          <Link href="#about">About</Link>
          <Link href="#claw">$Claw</Link>
          <Link href="#roadmap">Roadmap</Link>
        </MenuLinks>
      </Section>
      <Section>
        <SocialLinks isOpen={isOpen}>
          <div style={{ display: "flex" }}>
            <Link href="https://discord.gg/xzS2kx4fcm">
              <DiscordIcon />
            </Link>
            <Link href="https://twitter.com/PantherParadoxx">
              <TwitterIcon />
            </Link>
            <Link href="#">
              <OpenSeaIcon />
            </Link>
          </div>
          <ConnectToMetaMask />
        </SocialLinks>
      </Section>
    </Container>
  );
};
