import { useRef, useLayoutEffect, useCallback } from "react"

export const useSafeDispatch = (dispatch) => {
  const mountedRef = useRef(false)

  useLayoutEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const safeDispatch = useCallback(
    (action) => {
      if (mountedRef.current) {
        dispatch(action)
      }
    },
    [dispatch]
  )

  return safeDispatch
}
